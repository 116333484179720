<template>
  <div id="memberexamine">
    <div class="header">
      <div class="tu">
        <img style="width: 140px; height: 79px" :src="find.cover_img" alt="" />
      </div>
      <div class="second">
        <div class="font1">
          {{ find.name }}

          <div class="third">
            <el-button type="text" @click="share">分享</el-button>
            <div class="driver"></div>
            <el-dropdown>
              <el-button type="text">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="showRemoveEdit">
                  <!-- /tool/member/newmember?type=2&member_id=30 -->
                  <p
                    @click="
                      $router.push({
                        path: '/tool/member/newmember',
                        query: { type: 2, member_id: $route.query.member_id },
                      })
                    "
                  >
                    编辑
                  </p>
                </el-dropdown-item>
                <el-dropdown-item v-if="showRemoveDisableAble">
                  <p @click="stop(1, 1)" v-if="find.status == 2">启用会员</p>
                  <p @click="stop(1, 2)" v-else>停用会员</p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p v-if="find.is_show == 1" @click="show(2, 2)">
                    不展示到网校
                  </p>
                  <p v-else @click="show(2, 1)">展示到网校</p>
                </el-dropdown-item>
                <el-dropdown-item v-if="showRemoveDel">
                  <p @click="del(3)">删除</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="font2">{{ find.describe }}</div>
        <div class="font3">
          <div class="type1">
            <span v-if="find.is_show == 1">
              <img
                src="../../../../assets/img/1.4.0/icon_dg备份 4.png"
                alt=""
              />
              <div class="font">展示到网校</div>
            </span>
            <span v-else>
              <img
                src="../../../../assets/img/1.4.0/icon_cw备份 7.png"
                alt=""
              />
              <div class="font">不展示到网校</div>
            </span>
          </div>
          <div class="type2">
            <span v-if="find.status == 1">
              <img
                src="../../../../assets/img/1.4.0/icon_dg备份 4.png"
                alt=""
              />
              <div class="font">已启用</div>
            </span>
            <span v-else>
              <img
                src="../../../../assets/img/1.4.0/icon_cw备份 7.png"
                alt=""
              />
              <div class="font">已停用</div>
            </span>
          </div>
          <div class="right">
            会员数：{{ find.num }} | 创建人：{{ find.uname }} | 创建时间：{{
              find.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="`会员列表(${find.s_count || 0})`"
          name="1"
        ></el-tab-pane>
        <el-tab-pane
          :label="`作废记录(${find.d_count || 0})`"
          name="2"
        ></el-tab-pane>
        <el-tab-pane label="订单管理 " name="3"></el-tab-pane>
      </el-tabs>

      <div class="contain">
        <template v-if="activeName == 1">
          <div class="search">
            <el-form
              ref="ruleIngredientsForm"
              label-width="100px"
              :model="search"
              class="flex-center"
              size="small"
              :rules="rules"
            >
              <el-form-item label="关键词" class="mr20">
                <div class="search-item search-item1 ">
                  <div class="vm select">
                    <el-select v-model="search.search_type">
                      <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="vm input">
                    <el-input
                      :placeholder="placeholder"
                      v-model="search.search"
                      clearable
                    ></el-input>
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="加入会员时间" class="mr20">
                <div class="vm select ">
                  <el-date-picker
                    style="width:340px"
                    v-model="search.join_time"
                    @change="
                      selectedTime(arguments[0], [
                        'small_join_time',
                        'large_join_time',
                      ])
                    "
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    unlink-panels
                    value-format="timestamp"
                    :default-time="['00:00:00', '23:59:59']"
                  ></el-date-picker>
                </div>
              </el-form-item>

              <el-form-item label="会员到期时间" class="mr20">
                <div class="vm select ">
                  <el-date-picker
                    style="width:340px"
                    v-model="search.expiration_time"
                    @change="
                      selectedTime(arguments[0], [
                        'small_expiration_time',
                        'large_expiration_time',
                      ])
                    "
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    unlink-panels
                    value-format="timestamp"
                    :default-time="['00:00:00', '23:59:59']"
                  ></el-date-picker>
                </div>
              </el-form-item>

              <el-form-item label="会员状态" class="mr20">
                <div class="vm select ">
                  <el-select
                    v-model="search.validity_status"
                    style="width:300px"
                  >
                    <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>

              <el-form-item label="剩余有效期" prop="large_valid_days">
                <div class="search-item8">
                  <div class="vm select">
                    <div class="flex-center" style="width:100%">
                      <el-input
                        size="small"
                        :value="search.small_valid_days"
                        @input="
                          search.small_valid_days = toNumber(arguments[0], true)
                        "
                        clearable
                      >
                        <template slot="append">天</template>
                      </el-input>
                      <span class="middle-space">至</span>
                      <el-input
                        size="small"
                        :value="search.large_valid_days"
                        @input="
                          search.large_valid_days = toNumber(arguments[0], true)
                        "
                        clearable
                      >
                        <template slot="append">天</template>
                      </el-input>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="类型" class="mr20">
                <div class="vm select ">
                  <el-select v-model="search.type" style="width:300px">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <el-button size="medium" type="primary" @click="toSearch">
            搜索
          </el-button>
        </template>
        <div class="addmember_right" v-if="activeName == 2">
          <el-select
            class="mr10"
            v-model="searchOption.type"
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            style="width:218px"
            v-model="searchOption.search"
            size="medium"
            placeholder="输入学员/真实姓名搜索"
          ></el-input>
        </div>
        <div class="addmenber">
          <el-button
            size="medium"
            type="primary"
            style="width: 126px"
            @click="add"
            v-if="activeName === '1' && showRemoveAddstudent"
          >
            添加学员
          </el-button>
        </div>

        <pagination2
          v-if="activeName == 1 || activeName == 2"
          @complete="complete"
          :option="activeName == 1 ? search2 : searchOption"
          ref="child"
          url="/courseMember/seeCourseMember"
          :key="activeName"
        >
          <template v-slot:default="{ tableData: { list } }">
            <el-table
              @sort-change="sort_change"
              :data="list"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              ref="table"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                v-if="activeName == 1"
                width="45px"
              ></el-table-column>

              <el-table-column label="会员" prop="number" min-width="100">
                <template slot-scope="{ row }">
                  <div class="table_member">
                    <div class="tu">
                      <img
                        style="height: 22px; width: 22px"
                        :src="row.uphoto"
                        alt=""
                      />
                    </div>
                    <div
                      class="name"
                      :title="
                        `${row.uname}${
                          !row.student_remarks
                            ? ''
                            : '(' + row.student_remarks + ')'
                        }`
                      "
                    >
                      {{ row.uname }}

                      <span v-if="row.student_remarks">
                        ({{ row.student_remarks }})
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="手机号" min-width="60" prop="umobile">
                <template slot-scope="{ row }">
                  <span v-if="row.umobile">{{ row.umobile }}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="会员天数">
                <template slot-scope="{ row }">{{ row.vaild_day }}天</template>
              </el-table-column>
              <el-table-column label="类型" prop="costprice" min-width="60">
                <template slot-scope="{ row }">
                  <span v-if="row.type == 1">用户购买</span>
                  <span v-else>手动设置</span>
                </template>
              </el-table-column>

              <el-table-column
                label="加入时间"
                prop="c_time"
                sortable="custom"
                width="150px"
              >
                <template slot-scope="{ row }">
                  {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column
                width="150px"
                label="到期时间"
                prop="expires_time"
                sortable="custom"
              >
                <template slot-scope="{ row }">
                  {{ row.expires_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column label="状态" v-if="activeName == 1">
                <template slot-scope="{ row }">
                  <span v-if="row.validity_status == 1">
                    有效
                  </span>
                  <span v-else>过期</span>
                </template>
              </el-table-column>

              <el-table-column label="剩余有效期" v-if="activeName == 1">
                <template slot-scope="{ row }">
                  <span v-if="row.valid">
                    {{ row.valid }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>

              <el-table-column
                v-if="activeName === '2'"
                label="作废时间"
                prop="d_time"
                sortable="custom"
              >
                <template slot-scope="{ row }">
                  {{ row.d_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                </template>
                <!-- formatTimeStamp('yyyy-MM-dd hh:mm') -->
              </el-table-column>

              <el-table-column
                label="操作"
                v-if="activeName == 1"
                min-width="150px"
              >
                <template slot-scope="{ row }">
                  <div class="btns">
                    <el-button type="text" @click="changeTime(row)">
                      添加剩余有效期
                    </el-button>

                    <!-- <el-dropdown
                      v-else
                      trigger="click"
                      placement="bottom-start"
                    >
                      <span class="el-dropdown-link cp" style="color: #1b9d97">
                        查看订单
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="(item, index) in row.orderList"
                          :key="index"
                        >
                          <p @click="single_member(item)">
                            查看
                            {{
                              item.order_time
                                | formatTimeStamp('yyyy-MM-dd hh:mm')
                            }}
                            购买订单
                          </p>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown> -->

                    <el-divider
                      v-if="showRemoveVoid"
                      direction="vertical"
                      class="fl"
                      style="background: #1b9d97"
                    ></el-divider>
                    <div class="fl" v-if="showRemoveVoid">
                      <el-button type="text" @click="toVoid(row)">
                        作废会员
                      </el-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>

          <template #footer v-if="activeName == 1">
            <div class="mt20 pb20">
              <el-button size="medium" @click="changeTimeMore">
                批量添加剩余有效期
              </el-button>
              <el-button size="medium" class="ml10" @click="toVoid(void_ids)">
                批量作废会员
              </el-button>
            </div>
          </template>
        </pagination2>

        <member-order v-else></member-order>
      </div>
    </div>
    <!-- 添加学员 -->
    <addmember
      v-if="status"
      :dialogstatus.sync="status"
      @reset="resetnumber"
    ></addmember>

    <!--修改有效期 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="400px">
      <div slot="title" style="font-size:18px">
        添加剩余有效期
        <span class="ml20">已选 {{ peopleNumber }} 人</span>
      </div>
      <div class="header2">
        <div class="guige">会员规格</div>
        <el-select
          style="width: 250px"
          v-model="sure.spec_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options_time"
            :key="item.spec_id"
            :label="`${item.vaild_day}天`"
            :value="item.spec_id"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="tijiao()" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <downewm v-if="status2" :val="find" :dialogstatus.sync="status2"></downewm>
  </div>
</template>
<script>
/**
 * @param {string} s
 * @return {boolean}
 */

import memberOrder from './memberOrder'
import downewm from '@cm/base/downewm'
import addmember from '@cm/base/addmember'
export default {
  name: 'memberexamine',
  components: {
    addmember,
    downewm,
    memberOrder,
  },

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (
        typeof this.search.large_valid_days === 'number' &&
        this.search.large_valid_days < this.search.small_valid_days
      ) {
        this.tosearch = false
        callback(new Error('最大天数不能小于最小天数'))
      } else {
        this.tosearch = true
        callback()
      }
    }
    return {
      dialogVisible: false,
      member_order_id: '', //点击订单的id
      selectOrderr: [], //会员订单列表
      activeName: '1',
      status2: false, //分享弹窗
      find: {}, //获取的顶部数据

      // 会员列表批量选择的数据
      selectList: [],

      search2: {
        status: 1,
      },

      searchOption: {
        status: 1,
        sort: '',
        order_type: '',
        member_id: this.$route.query.member_id,
        type: 0,
        search: '',
      },
      search: {
        member_id: this.member_id,
        m_id: '',
        join_time: 0,
        last_buy_time: 0,
        search_type: 1,
        search: '',
        small_join_time: '',
        large_join_time: '',
        small_expiration_time: '',
        large_expiration_time: '',
        validity_status: 0,
        small_valid_days: '',
        large_valid_days: '',
        status: 1,
      },

      options_time: [],

      options2: [
        { label: '全部', value: 0 },
        { label: '有效', value: 1 },
        { label: '过期', value: 2 },
      ],

      // 搜索-
      options1: [
        { label: '用户名', value: 1, placeholder: '输入用户名' },
        { label: '手机号', value: 2, placeholder: '输入手机号' },
      ],

      rules: {
        large_valid_days: [{ validator: validatePass2, trigger: 'blur' }],
      },

      //添加会员弹窗
      status: false,
      //搜索关键字
      searchKey: '',
      //全部类型
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '用户购买',
        },
        {
          value: 2,
          label: '手动设置',
        },
      ],
      value: 0,

      peopleNumber: '',

      changeTimeList: {},

      morechangeTimeList: [],

      sure: {
        spec_id: '',
        uids: [],
      },
    }
  },

  created() {
    this.search2.member_id = this.member_id
    this.courseMemberSpec()
  },

  computed: {
    void_ids() {
      return this.selectList.map(item => item.member_user_id)
    },

    uids() {
      return this.morechangeTimeList.map(item => item.user_id)
    },

    member_id() {
      return this.$route.query.member_id
    },
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.search_type)
        .placeholder
    },

    // 添加学员
    showRemoveAddstudent() {
      return this.$store.state.roots.includes(124)
    },
    // 作废会员
    showRemoveVoid() {
      return this.$store.state.roots.includes(125)
    },
    // 编辑
    showRemoveEdit() {
      return this.$store.state.roots.includes(126)
    },
    // 启用/停用会员
    showRemoveDisableAble() {
      return this.$store.state.roots.includes(127)
    },
    // 删除
    showRemoveDel() {
      return this.$store.state.roots.includes(128)
    },
  },
  methods: {
    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },

    changeTimeMore() {
      if (this.morechangeTimeList.length == 0) {
        this.$root.prompt('请先选择会员！')
        return
      }
      this.isSingle = false
      this.peopleNumber = this.morechangeTimeList.length
      this.dialogVisible = true
    },

    // 单个修改有效期
    changeTime(row) {
      this.changeTimeList = row
      this.peopleNumber = 1
      this.isSingle = true
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },

    // 修改有效期
    async tijiao() {
      const endVal = !this.isSingle ? this.uids : [this.changeTimeList.user_id]

      await this.$http({
        url: 'courseMember/addUser',
        data: {
          member_id: this.member_id,
          uids: endVal,
          spec_id: this.sure.spec_id,
        },
        successMsg: true,
      })
      this.$refs.child.reset()
      this.sure.spec_id = ''
      this.dialogVisible = false
    },

    dialogCancel() {
      this.changeTimeList = {}
      this.peopleNumber = ''
      this.sure.spec_id = ''
      this.dialogVisible = false
    },

    //规格列表获取
    courseMemberSpec() {
      this.$http({
        url: '/courseMember/courseMemberSpec',
        data: {
          member_id: this.member_id,
        },
        callback: ({ data }) => {
          this.options_time = data
        },
      })
    },

    //  列表选择的数据
    handleSelectionChange(val) {
      this.selectList = val
      this.morechangeTimeList = val
    },

    // 搜索
    toSearch() {
      this.search2 = _.assign({}, this.search, { member_id: this.member_id })
    },

    single_member(val) {
      this.$router.push({
        path: '/capitalOrder/detail',
        query: {
          member_order_id: val.member_order_id,
          type: 2,
        },
      })
    },
    //查看订单
    visible_change(val) {
      this.$http({
        url: '/courseMember/selectOrder',
        data: {
          member_user_id: val.member_user_id,
        },
        callback: ({ data }) => {
          this.selectOrderr = data
        },
      })
    },

    // 重置
    resetval() {
      this.searchOption = {
        status: 1,
        sort: '',
        order_type: '',
        member_id: this.$route.query.member_id,
        type: 0,
        search: '',
      }
      this.search = {
        member_id: this.member_id,
        m_id: '',
        join_time: 0,
        last_buy_time: 0,
        search_type: 1,
        search: '',
        small_join_time: '',
        large_join_time: '',
        small_expiration_time: '',
        large_expiration_time: '',
        validity_status: 0,
        small_valid_days: '',
        large_valid_days: '',
        status: 1,
      }
    },

    //切换tabs
    handleClick(val) {
      this.resetval()
      if (val.name == 2) {
        this.search2.status = 2
        this.searchOption.status = 2
      } else {
        this.search2.status = 1
        this.searchOption.status = 1
      }
    },
    //作废会员
    toVoid(val) {
      const endVal = Array.isArray(val) ? val : [val.member_user_id]
      if (Array.isArray(val) && this.selectList.length == 0) {
        this.$root.prompt('请先选择会员！')
        return
      }
      this.$confirm(
        '作废后，已选学员将失去会员权益并从列表移除！可重新选择会员规格，添加已作废的学员成为会员角色。',
        '作废会员',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/courseMember/remove',
            data: {
              member_user_id: endVal,
            },
            callback: ({ code }) => {
              if (code == 200) {
                this.$refs.child.reset()
              }
            },
          })
        })
        .catch(() => {})
    },
    //排序
    sort_change(val) {
      this.searchOption.sort = !val.order
        ? ''
        : val.order === 'ascending'
        ? 1
        : 2
      this.searchOption.order_type = !val.order
        ? ''
        : val.prop == 'c_time'
        ? 1
        : val.prop == 'expires_time'
        ? 2
        : 3
    },
    //添加学员后点击确认刷新列表数
    resetnumber() {
      this.status = false
      this.$refs.child.reset()
    },
    //展示到网校，不展示到网校
    show(val2, val3) {
      if (val3 == 2) {
        this.$confirm(
          '隐藏后不会在网校显示会员信息，只可单独设置学员为会员角色！',
          '不展示到网校',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.$http({
              url: '/courseMember/stopCourseMember',
              data: {
                type: val2,
                is_show: val3,
                member_id: this.member_id,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.$refs.child.reset()
              },
            })
          })
          .catch(() => {})
      } else {
        this.$http({
          url: '/courseMember/stopCourseMember',
          data: {
            type: val2,
            is_show: val3,
            member_id: this.member_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
        })
      }
    },
    //停用启用
    stop(val2, val3) {
      if (val3 == 2) {
        this.$confirm(
          '停用后，已获得该会员权益的学员将全部失效；重新启用会员功能，在有效期内的学员自动恢复权益！',
          '停用会员',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.$http({
              url: '/courseMember/stopCourseMember',
              data: {
                type: val2,
                status: val3,
                member_id: this.member_id,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.$refs.child.reset()
              },
            })
          })
          .catch(() => {})
      } else {
        this.$http({
          url: '/courseMember/stopCourseMember',
          data: {
            type: val2,
            status: val3,
            member_id: this.member_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '操作成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
        })
      }
    },
    //删除数据
    del(val2) {
      this.$confirm(
        '删除后，已获得该会员权益的学员将全部失效，不可恢复！',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/courseMember/stopCourseMember',
            data: {
              type: val2,
              member_id: this.member_id,
            },
            callback: () => {
              this.$root.prompt({
                msg: '操作成功',
                type: 'success',
              })
              this.$router.push({
                path: '/tool/member',
              })
            },
          })
        })
        .catch(() => {})
    },

    add() {
      this.status = true
    },
    complete({ data: { find } }) {
      this.find = find
    },
    share() {
      this.status2 = true
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dropdown-menu__item {
  width: 203px;
}
::v-deep .el-tabs__nav-scroll {
  line-height: 50px;
}
#memberexamine {
  background-color: #f5f5f5;

  .header2 {
    display: flex;
    align-items: center;
    .guige {
      margin-right: 30px;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
  }
  .search {
    margin-top: 28px;
    .middle-space {
      font-size: 14px;
      line-height: 19px;
      margin: 0 10px;
      color: rgba(51, 51, 51, 1);
    }
    ::v-deep .el-form {
      flex-wrap: wrap;
    }
    .search_type {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .el-form-item__content {
        height: auto;
      }
      .el-form-item {
        width: 33.3%;
        margin-bottom: 20px;
      }
    }
    ::v-deep .el-input,
    ::v-deep .select {
      width: 100%;
      .el-input__inner {
        width: 100%;
      }
    }
    ::v-deep .search-item1 {
      display: flex;
      width: 300px;
      .select {
        width: 130px;
        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .input {
        width: 100%;
        input {
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    ::v-deep .search-item2,
    ::v-deep .search-item6 {
      .select {
        width: 160px;
      }
    }
    ::v-deep .search-item3,
    ::v-deep .search-item4 {
      .el-date-editor {
        width: 292px;
      }
      .el-input__icon {
        line-height: 27px;
      }
    }
    ::v-deep .search-item5,
    ::v-deep .search-item7,
    ::v-deep .search-item8 {
      width: 341px;
      margin-right: 20px;
      .el-input {
        // width: 130px;
        position: relative;
        input[type='number'] {
          padding-right: 50px;
        }
        .el-input-group__append {
          position: absolute;
          right: 0;
          background: none;
          top: 6px;
          height: 100%;
          border: 0;
          // line-height: 36px;
          width: auto;
          padding: 0;
          padding-right: 10px;
        }
      }
    }
  }
  .header {
    padding: 20px;
    height: 120px;
    background-color: #fff;
    display: flex;
    box-sizing: border-box;
    .tu {
      width: 140px;
      height: 79px;
      img {
        border-radius: 4px;
      }
    }
    .second {
      margin: 0 0px 0px 20px;
      width: calc(100% - 154px);
      .font1 {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 14px;
        ::v-deep .el-dropdown {
          height: 8px;
        }
      }
      .font2 {
        margin: 10px 0 29px;
        font-size: 14px;
        color: #666666;
        line-height: 14px;
      }
      .font3 {
        display: flex;
        .type1,
        .type2 {
          display: flex;
          font-size: 12px;
          color: #666666;
          line-height: 12px;
          span {
            align-items: center;
            display: flex;

            img {
              width: 8px;
              height: 7px;
              margin-right: 6px;
            }
          }
        }
        .type2 {
          margin-left: 30px;
        }
        .right {
          margin-left: 40px;
          margin-right: 20px;
          font-size: 12px;
          color: #333333;
          line-height: 12px;
        }
      }
    }
    .third {
      height: 0;
      display: flex;
      .driver {
        height: 14px;
        width: 1px;
        margin: 0 10px;
        background-color: #1b9d97;
      }
      ::v-deep .el-button--text {
        padding: 0;
      }
    }
  }
  .main {
    margin-top: 20px;
    background-color: #fff;
    .contain {
      margin: 20px;
      .addmenber {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .addmember_right {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        ::v-deep .el-select > .el-input {
          width: 160px;
        }
        ::v-deep .el-input--medium {
          width: 218px;
        }
      }
      .btns {
        display: flex;
        align-items: center;
      }
      .table_member {
        display: flex;
        align-items: center;
        .tu {
          width: 22px;
          height: 22px;
          border-radius: 2px;
        }
        .name {
          margin-left: 10px;
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
