<template>
  <div id="downewm">
    <el-dialog
      class="dialogVisible"
      width="480px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_main">
        <div id="poster11">
          <img src="../../assets/img/1.4.0/img_fxbg (1).png" alt="" />
          <div class="qrcode" id="qrcode"></div>
          <div class="font1">
            <div class="font_first">{{ val.name }}</div>
            <div class="font_scond">{{ val.describe }}</div>
            <div class="font_third">- {{ title }} -</div>
          </div>

          <div class="result-pic" v-if="resultPic">
            <img :src="resultPic" />
          </div>
        </div>
        <div class="btns">
          <el-button type="text">
            <a download="poster.jpg" :href="resultPic">下载海报</a>
          </el-button>
          <el-button type="text" @click="downEwm">下载二维码</el-button>
          <el-button
            type="text"
            class="for-copy"
            :data-clipboard-text="val.url"
          >
            复制链接
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import ClipboardJS from 'clipboard'
import QRCode from '@/assets/js/qrcode'
export default {
  name: 'downewm',
  data() {
    return {
      dialogVisible: false,

      resultPic: '', //生成的海报
      complete_erweima: '', //海报生成中的是未true
    }
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    val: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.create_ewm()
    })
  },

  computed: {
    title() {
      return this.$store.state.userInfo.jg_title
    },
  },
  methods: {
    // 生成二维码
    create_ewm() {
      const size = 108
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.val.url, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
      setTimeout(() => {
        this.create()
      }, 1000)
    },
    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },
    //生成海报
    create() {
      window.scrollTo(0, 0)
      const poster11 = document.getElementById('poster11')
      html2canvas(poster11, {
        useCORS: true,
        logging: false,
      })
        .then((canvas) => {
          this.resultPic = canvas.toDataURL('image/png')
          this.complete_erweima = false
        })
        .catch((res) => {
          console.log(res)
        })
      this.initCopy()
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    // 下载二维码
    downEwm() {
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .dialogVisible {
  .el-dialog__body {
    padding: 0 0 40px;
  }
  .dialogVisible_main {
    width: 280px;
    margin: 0 auto;
    position: relative;

    .qrcode {
      box-sizing: border-box;
      overflow: hidden;
      padding: 5px;
      height: 118px;
      width: 118px;
      background-color: #fff;
      position: absolute;
      top: 49px;
      left: 50%;
      transform: translateX(-50%);
    }
    .font1 {
      height: 92px;
      width: 100%;
      position: absolute;
      top: 187px;
      .font_first {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        line-height: 18px;
      }
      .font_scond {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        margin: 12px 0 25px;
      }
      .font_third {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
      }
    }
    .btns {
      display: flex;
      justify-content: center;

      a {
        text-decoration: none;
        color: #0aa29b;
      }
    }

    .result-pic {
      width: 280px;
      height: 314px;
      position: absolute;
      top: 0px;
      left: 0px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>